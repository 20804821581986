<!-- <div class="screen-dialog-container" >
  <h2 mat-dialog-title>{{ title | translate }}</h2>

  <mat-dialog-content class="screen-dialog-content">
    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions class="screen-dialog-actions">
    <button mat-button mat-dialog-close (click)="onCancel()">
      {{ 'COMMON.CANCEL' | translate }}
    </button>
    <button mat-flat-button color="primary" [disabled]="disableCondition" (click)="onSubmit()">
      {{ save_action_text | translate }}
    </button>
  </mat-dialog-actions>
</div> -->
<div class="d-flex align-items-center justify-content-between m-b-4 p-x-24" [style.max-width.px]="dialogContentWidth" [style.min-width.px]="dialogContentMinWidth">
  <h2 class="m-y-10 m-x-0">{{ title| translate }}</h2>
  <button matDialogClose mat-icon-button color="warn">
    <mat-icon>close</mat-icon>
  </button>
</div>

  <mat-dialog-content class="mat-typography" [style.max-width.px]="dialogContentWidth" [style.min-width.px]="dialogContentMinWidth"  style="min-height: calc(100vh - 130px)">
    <ng-content></ng-content>
  </mat-dialog-content>
    @if (twoActions) {
  <mat-dialog-actions class="p-8 p-t-0 row">
    <div class="col-sm-12 col-md-5 m-b-6">
      <button
        class="w-100"
        mat-raised-button
        [disabled]="disableCondition"
        (click)="onPrimaryAction()"
        color="primary">
        {{ primaryActionLabel | translate }}
      </button>
    </div>
    <div class="col-sm-12 col-md-5 m-b-6">
      <button
        class="w-100"
        mat-flat-button
        [disabled]="disableCondition"
        (click)="onAccentAction()"
        color="accent">
        {{ accentActionLabel | translate }}
      </button>

    </div>
    <div class="col-sm-12 col-md-2 m-b-6">
      <button
        class="w-100"
        mat-raised-button (click)="onCancel()">
        {{ "COMMON.CANCEL" | translate }}
      </button>

    </div>

  </mat-dialog-actions>
    } @else {
      <mat-dialog-actions class="p-22 p-t-0">
        @if (!noAction) {
          <button class="col-sm-5 m-b-6"
                  mat-flat-button
                  [disabled]="disableCondition"
                  (click)="onSubmit()"
                  color="primary">
            {{ save_action_text | translate }}
          </button>
        }
      <button class="col-sm-5 m-b-6" mat-raised-button (click)="onCancel()">
        {{ "COMMON.CANCEL" | translate }}
      </button>
      </mat-dialog-actions>
    }



import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  grid_view: boolean = true;
  list_view: boolean = false;
  constructor() { }
  listView(){
    this.grid_view = false;
    this.list_view = true;
    localStorage.setItem('VIEW', JSON.stringify('list'));
    console.log('Grid View Activated:', this.grid_view);
  }
  gridView(){
    this.grid_view = true;
    this.list_view = false;
    localStorage.setItem('VIEW', JSON.stringify('grid'));
  }
  setView(){
    let view = JSON.parse(localStorage.getItem('VIEW'));
    if (view){
      if (view == 'grid'){
        this.grid_view = true;
        this.list_view = false;
      }
      if (view == 'list'){
        this.grid_view = false;
        this.list_view = true;
      }
    }else{
      localStorage.setItem('VIEW', JSON.stringify('grid'));
    }

  }

}

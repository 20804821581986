import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {PopUpDialogComponent} from '../pop-up-dialog/pop-up-dialog.component';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatOption, MatSelect,} from '@angular/material/select';
import {NgForOf} from '@angular/common';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  standalone: true,
  imports: [
    PopUpDialogComponent,
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    NgForOf,
    TranslatePipe,
  ],
  styleUrl: './filter-dialog.component.scss'
})
export class FilterDialogComponent {
  title: string = '';
  filterOptions: any[] = [];
  selectedOptions: { [key: string]: any } = {};

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.title = this.data.title;
      this.filterOptions = this.data.filterOptions;

      this.filterOptions.forEach(optionGroup => {
        this.selectedOptions[optionGroup.id] = null;
      });
    }
  }

  get disableCondition(): boolean {
    return Object.values(this.selectedOptions).some(value => !value);
  }

  onSelectChange(selectId: string, event: any) {
    this.selectedOptions[selectId] = event.value;
  }

  onSubmit() {
    this.dialogRef.close(this.selectedOptions);
  }
}

import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomRouter {

  readonly router: Router = inject(Router);

  /**
   * navigate by id
   * @param path
   * @param id
   */
  navigateById(path:string,id: any) {
    this.router.navigate([path, id]);
  }

  /**
   * navigate by id
   * @param path
   */
  navigateByUrl(path:string) {
    this.router.navigateByUrl(path);
  }

}

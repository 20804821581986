import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {MatCard} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {TranslatePipe} from '@ngx-translate/core';
import {environment} from '../../../../../environments/environment';
import {TruncatePipe} from '../../../../core/pipes';

@Component({
  selector: 'item-card',
  standalone: true,
  imports: [
    MatCard,
    MatIcon,
    MatIconButton,
    NgIf,
    NgOptimizedImage,
    TranslatePipe,
    TruncatePipe
  ],
  templateUrl: './item-card.component.html'
})
export class ItemCardComponent{
  @Output() delete = new EventEmitter();
  @Output() showDetails = new EventEmitter();
  @Output() increment = new EventEmitter();
  @Output() decrement = new EventEmitter();
  @Input() creation: boolean =false;
  @Input() itemName: string;
  @Input() itemNumber: string;
  @Input() status: any;
  @Input() imagePath: string;
  @Input() barcode: string;
  @Input() details!: { key: string, value: any }[];
  @Input() showCountAction: boolean = false;
  @Input() itemCount: any;


  baseImageUrl = environment.baseImageUrl;
  isPrepaidInvoice: boolean = false;

  ngOnInit() {
    this.isPrepaidInvoice = localStorage.getItem('isPrepaidInvoice') === 'true';
  }

  emitShowDetails() {
    this.showDetails.emit();
  }
  emitDelete() {
    this.delete.emit();
  }
  emitIncrement() {
    this.increment.emit();
  }
  emitDecrement() {
    this.decrement.emit();
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatBadge } from '@angular/material/badge';

@Component({
  selector: 'floating-button',
  standalone: true,
  imports: [
    MatFabButton,
    MatIcon,
    MatBadge
  ],
  templateUrl: './floating-button.component.html'
})
export class FloatingButtonComponent {
  @Output() action = new EventEmitter();
  @Input() icon: string;
  @Input() count: any;
  @Input() disabled: boolean = false;

  emitAction() {
    this.action.emit();
  }
}

import {inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ThemeConfig, ThemeState } from '../interfaces/theme.interface';
import {finalize } from 'rxjs/operators';
import {BaseService} from '../../../shared/service';
import {CoreService} from '../../../core/services';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private notify$ = new BehaviorSubject<Record<string, any>>({});
  private readonly defaultTheme: ThemeConfig = {
    colors: {
      primary: {
        background: '#92278f',
        foreground: '#ffffff'
      },
      accent: {
        background: '#e5458f',
        foreground: '#ffffff'
      },
      text: {
        primary: '#29343d',
        secondary: '#526b7a'
      },
      icon: '#29343d'
    }
  };
  // Base URL for the apI
  private apiUrl = 'api/v1/customer-theme';
  private state = new BehaviorSubject<ThemeState>({
    loading: false,
    saving: false,
    config: this.defaultTheme
  });
  // get the id of the customer from local storage
  branchConfigJson = localStorage.getItem('BRANCH_CONFIG');
  branchConfig = this.branchConfigJson !== undefined ? JSON.parse(this.branchConfigJson) : null;
  themeId = this.branchConfig ? this.branchConfig.customerId : null;
  private _isDarkMode = false;
  options = this.settings.getOptions();
  _base = inject(BaseService);

  constructor(private http: HttpClient,private settings: CoreService) {
    this.loadThemePreference();
  }
  getState(): Observable<ThemeState> {
    return this.state.asObservable();
  }
  async saveTheme(config: ThemeConfig): Promise<void> {
    try {
      this.state.next({ ...this.state.value, saving: true });

      // Save theme in the local storage
      //localStorage.setItem('theme', JSON.stringify(config));

      // Prepare the request body
      const requestBody = {
        primary: config.colors.primary,
        accent: config.colors.accent,
        text: config.colors.text,
        icon: config.colors.icon,
      };
     let res =  await this.http.post(`${this.apiUrl}/customer/${this.themeId}`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        }
      }).toPromise();

      console.log(res);

      if (res['success']) {

        let theme = res['data'];
        document.documentElement.style.setProperty('--mat-theme-primary-color', theme.primary.background);
        document.documentElement.style.setProperty('--mat-theme-accent-color', theme.accent.background);

        localStorage.setItem('theme', JSON.stringify(res['data']));
      }

      // Update the state after successful save
      this.state.next({
        loading: false,
        saving: false,
        config,
      });
    } catch (error) {
      this.state.next({
        ...this.state.value,
        saving: false,
        error: error instanceof Error ? error.message : 'Failed to save theme',
      });
    }
  }

  resetTheme(themeId: string): void {
    this.state.next({ ...this.state.value, saving: true });

    // Prepare the payload with default theme
    const payload = {
      id: themeId,
      customerTheme: this.defaultTheme,
    };

    // Make the PUT request to reset the theme
    this.http
      .put(`${this.apiUrl}/set-default-theme/${themeId}`, payload)
      .pipe(
        finalize(() =>
          this.state.next({
            ...this.state.value,
            saving: false
          })
        )
      )
      .subscribe({
        next: (res:any) => {
          if (res.success){
            localStorage.setItem('theme', JSON.stringify(res.data));
            document.documentElement.style.setProperty('--mat-theme-primary-color', '#92278f');
            document.documentElement.style.setProperty('--mat-theme-accent-color', '#e5458f');
            console.log('Theme reset successfully.');
            this.state.next({
              loading: false,
              saving: false,
              config: this.defaultTheme
            });
          }
        },
        error: (error) => {
          console.error('Failed to reset theme:', error);
          this.state.next({
            ...this.state.value,
            saving: false,
            error: 'Failed to reset theme'
          });
        }
      });
  }

  applyStandardTheme(theme: any) {
    console.log(`${this.apiUrl}/customer/${this.themeId}`);
    return this._base.post(theme,`${this.apiUrl}/customer/${this.themeId}`);
  }

// function that check the theme of the customer wehen the page is reloaded
initializeTheme(): void {
  if (this.themeId) {
    this.http.get(`${this.apiUrl}/customer/${this.themeId}`).subscribe({
      next: (response: any) => {
        if (response && response.data) {
          const themeConfig: ThemeConfig = {
            colors: {
              primary: response.data.primary,
              accent: response.data.accent,
              text: response.data.text,
              icon: response.data.icon
            }
          };
          this.state.next({
            loading: false,
            saving: false,
            config: themeConfig
          });
          localStorage.setItem('theme', JSON.stringify(response.data));
        } else {
          this.resetTheme(this.themeId);
        }
      },
      error: (error) => {
        console.error('Error while fetching theme:', error);
        this.resetTheme(this.themeId);
      }
    });
  } else {
    console.warn('No theme ID found in local storage.');
    this.resetTheme(this.themeId);
  }
}

  get isDarkMode(): boolean {
    return this._isDarkMode;
  }


  private loadThemePreference(): void {
    const theme = this.getCookie('theme');
    this._isDarkMode = theme === 'dark';
    if (this._isDarkMode) {
      document.body.classList.add('dark-theme');
    }
  }

  toggleTheme(): void {
    this._isDarkMode = !this._isDarkMode;

    document.body.classList.toggle('dark-theme', this._isDarkMode);
    document.body.classList.toggle('light-theme', !this._isDarkMode);
    this.setCookie('theme', this._isDarkMode ? 'dark' : 'light', 365);
    this.notify$.next(this.options);
    console.log(`Theme switched to: ${this._isDarkMode ? 'Dark' : 'Light'}`);
  }

  private setCookie(name: string, value: string, days: number): void {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  }

  getThemeColor(variable: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
  }


  private getCookie(name: string): string | null {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [key, value] = cookie.split('=');
      if (key === name) {
        return value;
      }
    }
    return null;
  }
}

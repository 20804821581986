<pop-up-dialog
  [disableCondition]="disableCondition"
  [title]="title"
  (submitDialog)="onSubmit()"
  [save_action_text]="'COMMON.SAVE'"
>
  <ng-container *ngFor="let optionGroup of filterOptions">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{ optionGroup.label | translate }}</mat-label>
      <mat-select [value]="selectedOptions[optionGroup.id]" (selectionChange)="onSelectChange(optionGroup.id, $event)">
        <mat-option *ngFor="let option of optionGroup.options" [value]="option.id">
          {{ (option.bundleCode ? option.bundleCode : option.name) | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</pop-up-dialog>

import {Component, Input, OnInit} from '@angular/core';
import {MatMiniFabButton} from '@angular/material/button';
import {MatMenuTrigger} from '@angular/material/menu';
import {NgClass} from '@angular/common';

@Component({
  selector: 'more-vert-button',
  templateUrl: './more-vert-button.component.html',
  standalone: true,
  imports: [
    MatMiniFabButton,
    MatMenuTrigger,
    NgClass
  ],
})
export class MoreVertButtonComponent {
  @Input() customClass:boolean = false;
  @Input() actionsMenu:any;
}

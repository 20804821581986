import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DecimalPipe, NgStyle, NgIf, NgClass} from '@angular/common';
import {TranslatePipe} from '@ngx-translate/core';
import {Constant} from '../../../../shared/constant/constant';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { PopUpDialogComponent } from '../../dialogs/pop-up-dialog/pop-up-dialog.component';
import {FilterDialogComponent} from '../../dialogs/filter-dialog/filter-dialog.component';
import {SnackService} from '../../../../shared/components/snack/snack.service';

@Component({
  selector: 'dashboard-card',
  standalone: true,
  imports: [
    DecimalPipe,
    TranslatePipe,
    NgStyle,
    NgIf,
    NgClass,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent {
  @Input() label: string = '';
  @Input() value: number = 0;
  @Input() percentage: number = 0;
  @Input() index: number = 0;
  @Input() hasAction: boolean = false;
  @Input() hasFilter: boolean = false;
  @Input() filterOptions: any[] = [];

  @Output() action = new EventEmitter<void>();
  @Output() filterChange = new EventEmitter<any>();

  appliedFilter: string | null = null;

  // Define a more vibrant color palette
  colors: string[] = [
    '#FF5733', // Red
    '#33FF57', // Green
    '#3357FF', // Blue
    '#FF33A8', // Pink
    '#FF8F33', // Orange
    '#8F33FF', // Purple
  ];

  constructor(
    private constant: Constant,
    private dialog: MatDialog,
    private snack: SnackService
  ) {}

  get cardColor(): string {
    // Use the vibrant colors array for border-left
    return this.colors[this.index % this.colors.length];
  }

  emitAction() {
    this.action.emit();
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: {
        title:this.label,
        filterOptions: [
          {id: this.label, label: this.label, options: this.filterOptions},
        ]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.snack.showSnack('no filter selected', 'info');
      } else {
        if (result[this.label]) {
              this.appliedFilter = result[this.label];
              this.filterChange.emit(result[this.label]);
        }
      }
    });
    // const dialogRef = this.dialog.open(PopUpDialogComponent, {
    //   data: {
    //     title: `Filter ${this.label}`,
    //     filterGroups: Array.isArray(this.filterOptions) ?
    //       [{ label: this.label, options: this.filterOptions }] :
    //       this.filterOptions,
    //     selectedFilter: this.appliedFilter
    //   }
    // });
    //
    // dialogRef.afterClosed().subscribe(result => {
    //   // If user clicks Cancel, result may be undefined
    //   if (result !== undefined) {
    //     this.appliedFilter = result; // store the new filter
    //     this.filterChange.emit(this.appliedFilter);
    //   }
    // });
  }
}

import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {DatePipe, NgStyle} from '@angular/common';
import {MatCard} from '@angular/material/card';
import {MatDivider} from '@angular/material/divider';
import {TranslatePipe} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';
import {TruncatePipe} from '../../../../core/pipes';

@Component({
  selector: 'grid-view-card',
  standalone: true,
  imports: [
    DatePipe,
    MatCard,
    MatDivider,
    TranslatePipe,
    RouterLink,
    NgStyle,
    TruncatePipe
  ],
  templateUrl: './grid-view-card.component.html'
})
export class GridViewCardComponent {
  @Input() id!: string;
  @Input() status!: any;
  // @Input() status!: any[];
  @Input() number!: string;
  @Input() createdDate!: string;
  @Input() routeLink: string;
  @Input() details!: { key: string, value: any }[];
  @ContentChild(TemplateRef) content: TemplateRef<any>;

  getStatusArray(): any[] {
    return Array.isArray(this.status) ? this.status : [this.status];
}
}

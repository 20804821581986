import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomDate {
  /**
   * format date with format yyyy-MM-dd HH:mm:ss
   * @param date
   */
  formatDate(date: any) {
    return new Date(date)
      .toISOString()
      .replace(/T/, ' ') // replace T with a space
      .replace(/\..+/, '');
  }
}

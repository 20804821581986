import {Component, Input} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'actions-button',
  standalone: true,
  imports: [
    MatIconButton,
    MatMenuTrigger,
    MatIcon
  ],
  templateUrl: './actions-button.component.html'
})
export class ActionsButtonComponent {
  @Input() actionsMenu:any;
  @Input() primary:boolean = false;
  @Input() accent:boolean = false;
}
